import './App.css';
import logoImg from "./icons/logo.svg"
import sendImg from "./icons/send.svg"
import chatLogoImg from "./icons/ln-chat-logo.svg"
import chatUser from "./icons/user-icon.svg"
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import {getMessage} from "@testing-library/jest-dom/dist/utils";
import remarkGfm from "remark-gfm";
import remarkTables from "remark-grid-tables"
import remarkHtml from "remark-html";


function App() {
    const [items, setItems] = useState([])
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [genMsg, setGenMsg] = useState('')
    const [msg, setMsg] = useState([])

    const chatContainerRef = useRef(null);

    const handleScrollToBottom = () => {
        const chatContainer = chatContainerRef.current;
        chatContainer.scrollTo({
            top: chatContainer.scrollHeight,
            behavior: 'auto' // Optional: smooth scrolling animation
        });
    };

    const moveMessageToHistory = () => {

    }

    const handleEventSourceWithBody = async (conversation_uuid, user_uuid, message_uuid) => {
        const response = await fetch('https://ai.legalnodes.com/copilot/stream', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                conversation_uuid, user_uuid, message_uuid, message
            })
        })

        setLoading(true)
        let isTechDetailsDone = false

        // eslint-disable-next-line no-undef
        const reader = response.body.pipeThrough(new TextDecoderStream()).getReader()
        // eslint-disable-next-line no-constant-condition
        while (true) {

            let { value, done } = await reader.read();
            if (done) {
                setGenMsg(prevState => (prevState + `<p class="done"></p>`))
                break;
            }

            if (value.includes('<div class="tech-details-end"/>')) {
                isTechDetailsDone = true
            }

            if (isTechDetailsDone && !value.includes('<div class="tech-details-end"/>')) {
                if (value.trimRight().trimLeft() === "FINAL RESULTS") {
                    setMsg(prevState1 => [...prevState1, '## FINAL RESULTS'])
                } else {
                    setMsg(prevState1 => [...prevState1, value])
                }

                handleScrollToBottom()
            }
            //console.log(value)
        }
        setLoading(false)
    }

    const createData = async () => {
        setLoading(true)
        try {
            const { status, data } = await axios.post(`https://ai.legalnodes.com/copilot`, { message })

            if (status === 200) {
                await handleEventSourceWithBody(data.conversation_uuid, data.user_uuid, data.message_uuid)
            } else if (status === 422) {
                alert("You have exceeded the limit")
            }
        } catch (e) {
            if (e?.response?.status === 422) {
                alert(e?.response?.data?.message)
            }
        }
        setLoading(false)
    }

    const setTextFromQuestion = (e) => {
        setMessage(e.target.textContent)
    }

    useEffect(() => {
        (async () => {
            const { status, data } = await axios.get(`https://ai.legalnodes.com/copilot/access`)
        })()
    },[])

    useEffect(() => {
        /*if (genMsg.includes('<p class="done"></p>')) {
            setItems([
                ...items,
                { question: message, text: `<div>${genMsg}</div>` }
            ])
            setMsg('')
            setMessage('')
        }*/
    }, [genMsg])

    return (
      <div className="app">
        <header>
            <img src={logoImg} alt=""/>
        </header>
          <div className="chat">

              <div className="chat__container" ref={chatContainerRef}>
                  <div className="system-questions">
                      <div className="system-questions__heading">
                          <img src={chatLogoImg} alt=""/>
                          <span>LegalNodes copilot, how can I help you?</span>
                      </div>
                      <div className={'system-questions__questions'}>
                          <div onClick={setTextFromQuestion} className="questions__question">
                              Tax implications for company in Estonia
                          </div>
                          <div onClick={setTextFromQuestion} className="questions__question">
                              Company wants to organize CEX service in Brazil
                          </div>
                          <div onClick={setTextFromQuestion} className="questions__question">
                              Check tax situation if the Legal entity from Portugal and the target country is Singapore?
                          </div>
                          <div onClick={setTextFromQuestion} className="questions__question">
                              Check tax situation for individual resident from Ukraine who wants incorporate company in UK?
                          </div>
                      </div>
                  </div>
                  {(msg.length > 0 || items.length > 0) && <div className="system-questions">
                      {
                          items.map(el => (
                              <>
                                  <div className={'system-question__user'}>
                                      <img src={chatUser} alt=""/>
                                      <p>{el.question}</p>
                                  </div>
                                  <div className="system-question__container">
                                      <div dangerouslySetInnerHTML={{__html: el?.text}}/>
                                  </div>
                              </>
                          ))
                      }
                      {msg.length > 0 && <>
                          <div className={'system-question__user'}>
                              <img src={chatUser} alt=""/>
                              <p>Tax implications for company in Estonia</p>
                          </div>
                          <div className="system-question__container">
                              {/*<div dangerouslySetInnerHTML={{__html: genMsg}}/>*/}
                              {
                                  msg.map(el => (
                                      <ReactMarkdown remarkPlugins={[remarkGfm, remarkHtml]}>{el}</ReactMarkdown>
                                  ))
                              }
                          </div>
                      </>}
                  </div>}
              </div>
              <div className="chat__message">
                  <div className="chat__message-field">
                      <input
                          disabled={loading}
                          type="text"
                          value={message}
                          onChange={e => setMessage(e.target.value)}
                      />
                  </div>
                  <button disabled={message.trim().length === 0 || loading} onClick={createData}>
                      {loading && <div className="lds-ring">
                          <div/><div/><div/><div/>
                      </div>}
                      <img src={sendImg} alt=""/>
                  </button>
              </div>
          </div>
      </div>
    );
}

export default App;
